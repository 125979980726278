import React, { useMemo } from 'react';

import { debounce } from 'lodash';

import { InputAdornment, TextFieldProps } from '@mui/material';
import PdfEditorTextField from '@app/components/ui/input/pdf-editor-text-field';
import SearchIcon from '@app/components/icons/search-icon';

interface ISearchInputProps {
    handleSearch: (event: any) => void;
}

export default function SearchInput({ handleSearch, className, placeholder, id }: TextFieldProps & ISearchInputProps) {
    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <PdfEditorTextField
            data-testid={id}
            onChange={debouncedResults}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon className="cursor-pointer" />
                    </InputAdornment>
                ),
                sx: {
                    height: 48,
                    paddingX: '12px',
                    paddingY: '12px',
                    backgroundColor: 'white'
                }
            }}
            placeholder={placeholder}
            className={`w-0  !rounded-md border-white-4 opacity-0 sm:w-auto sm:opacity-100 ${className} `}
        />
    );
}
