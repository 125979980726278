import React from 'react';
import Image from 'next/image';
import LoadingImage from '@app/assets/image/loader.gif';
import { useTranslation } from 'next-i18next';

function LoadingModal({ title }: { title: string }) {
    const { t } = useTranslation(['common']);
    return (
        <div className="flex flex-col items-center justify-center gap-10 px-6">
            <Image alt={'loading image'} src={LoadingImage} objectFit="fill" height={50} width={50} />
            <div className="flex flex-col items-center gap-1">
                <p className="h2 text-white">{title}</p>
                <p className="p2 text-white">{t('pleaseWaitAMoment')}</p>
            </div>
        </div>
    );
}

export default LoadingModal;
